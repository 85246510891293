<template>
  <div class="wid_1830">
      <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
        
    };
  },
  created() {
   
  },
  methods:{
    
  }
};
</script>

<style lang="scss" scoped>
.wid_1830{
    width: 100%;
    margin: 0 auto;
    height: calc(100vh - 150px);
}
</style>